@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.img.badge{
  width: 150px;
}
.img.badge img{
 width: 100%;
 object-fit: contain;
 border-radius: 0;
}
.grid3Home .items{
  margin: 0 auto;

}
.body{
  overflow: hidden;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1B2737;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1B2737;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: #fff;
  color: #1B2737;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover ,.button{
  box-shadow: none;
}
.primary-btn {
  background-color: #1B2737;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
  padding-bottom:0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #FF9900;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 32px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 25px;
  font-size: 16px;
}
.home .hprice  {
  margin-top: 4rem;
  background-color: antiquewhite;
}
.home .hprice .items {
  border-radius: 5px;
}

.back {
  background-image: url("../public/images/WhatsApp\ Image\ 2024-01-25\ at\ 15.06.52.jpeg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  background-position-y: top ;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 55vh;
  padding-top: 14%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}
.back h1 {
  font-size: 100px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin-back {
  margin-top: 16.3%;
}
.margin {
  margin-top: 40.3%;
}
.aws-page .margin{
  margin-top: 40%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.grid4-custom {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #FF9900;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #FF9900;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.grid3Home {
  margin: 0 auto;
  width: 750px;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
iframe{
  width: 570px;
  height: 235px;
}
@media screen and (max-width: 768px) {
  .flexSB {
    flex-direction: column;
  }
  .row {
    width: 100%;
  }
  .aboutHome.card .left {
   display: none;
}
  #heading h1 {
    font-size: 30px;

  }
  .margin{
    display: none;
  }
  iframe{
    width: 570px;
    height: 235px;
  }
  .row.youtube{
    margin-top: 2rem;
  }
  .aboutHome.card,.aboutHome.card .container {
      margin: 0 !important;
  }

  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
    position: relative;
  }
}
.teks-collab {
  MARGIN-TOP: -5REM;
}

.break{
  background-color: #FF9900;
  padding: 2rem 0;
  text-align: center;
  color: #fff;
}

.break h2{
  font-size: 21px;
  text-align: center;
  padding: 0 2rem;
}
.homeAbout {
  width: 80%;
  margin: 2rem auto;
}
.mt-5{
  margin-top: 1rem;
}

.aboutHome.card li{
  list-style-type: circle;
  font-size: 21px;
}
.aboutHome.card .left {
  width: 30%;
  height: 400px;
}
.aboutHome.card .left img{
 object-fit: cover;
 border-radius: 15px;
}
.aboutHome.card .container{
justify-content: center;
 border-radius: 15px;
 margin: 5rem;
 margin-top: 3rem;
 margin-bottom: 3rem;

}
.aboutHome.card {
margin-top: 2rem;
margin-bottom: 1rem;
}

button a, .button a{
  color: #fff;
}
/* media querry */
.sdp .price ul ,.aws-page  .price ul{
  margin-top: 1rem;
  padding-left: 0;
}
.home .wrap-image-home{
  margin: 0 auto;
  width: 1000px;
  margin-bottom: 4rem;
}
.home .wrap-image-home img{
  width: 100%;
  object-fit: cover;
}
.homeAbout p{
  text-align: justify;
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { 
  .home .wrap-image-home{
    margin: 0 auto;
    width: 1200px;
    margin-bottom: 3rem;
  }
  #heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #FF9900;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 32px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}
  iframe{
    width: 570px;
    height: 335px;
  }
  #heading h1 {
    font-size: 45px;
  }
  .break h2{
    font-size: 26px;
  }
}

.image-diagram{
  width: 70%;
margin: 0 auto;
margin-top: 2rem;
}

.image-diagram img{
  width: 100%;
  object-fit: cover;
}