.blog-solution img {
  width: 100%;
  height: 100%;
}
 .text {
  text-align: justify;
}
.blog-solution.storage h1{
  text-align: center;
  margin-top: 2rem;
  font-size: 24px;
}
.blog-solution.storage .custom h1{
  text-align: center;
  margin-top: 2rem;
  font-size: 16px;
}
.blog-solution h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog-solution h1:hover {
  color: #1eb2a6;
  cursor: pointer;
}
.blog-solution span {
  text-transform: uppercase;
  font-weight: 500;
  color: #1eb2a6;
}
.blog-solution span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog-solution span:nth-last-child(1) label {
  color: #1eb2a6;
}
.blog-solution p {
  color: grey;
  padding: 0 2rem;
}
.blog-solution {
  margin-bottom: 4rem;
}
.blog-solution h2 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 18px;
}
.blog-solution.storage .img{
  height: 400px;
}
.blog-solution.storage .custom.img{
  height: 200px;
}

.blog-solution.storage p {
  padding-bottom: 2rem;
}
.blog-solution.storage .custom p {
  font-size:13px ;
}