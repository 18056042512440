.hero {
  background-image: url("../../../../public/images/test1.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-y: center;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  width: 100%;
  height: 100vh;
  padding-top: 16.8%;
  padding-right: 50px;
  color: #000;
}
.hero #heading h3 {
  color: #FF9900;
}
.hero #heading h1 {
  color: #1B2737  ;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero .container{
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.hero .teks{
  width: 45%;
}
.hero .youtube{
  display: flex;
  justify-content: end;
}
.hero .youtube iframe{
  border-radius: 10px;

}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    height: 100vh;
    position: relative;
  }
  .hero .container{
   flex-direction: column;
  }
  .hero .row {
    width: 100%;
  }
}
