.blog img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.blog .text {
  text-align: center;
}
h2.submenuTitle{
font-size: 2.5vw;
margin: 0 auto;
text-align: center;
width: 80%;
font-weight: 400;
margin-bottom: 2rem
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog h1:hover {
  color: #1eb2a6;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #1eb2a6;
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: #1eb2a6;
}
.blog p {
  color: grey;
}
.blog {
  margin-bottom: 2rem;
}
h1.title{
  font-size: 42px;
  text-align: center;
  padding-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero-sdp {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero-sdp .row {
    width: 100%;
  }
}
