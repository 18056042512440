.hero-sdp.aws {
  background-image: url(../../../../../public/images/test1.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position-y: center;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #000;
}
.hero-sdp #heading h3 {
  color: #FF9900;
}
.hero-sdp #heading h1 {
  color: #1B2737  ;
}
.hero-sdp #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero-sdp .container{
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.hero-sdp .teks{
  width: 55%;
}
.hero-sdp .yt{
  display: flex;
  justify-content: start;
}
.hero-sdp .yt img{
  width: 420px;
  height: 270px;
}
.hero-sdp .yt iframe, .hero-sdp img{
  border-radius: 10px;

}
@media screen and (max-width: 768px) {
  .paragraf{
    padding: 2rem;
    padding-top: 3rem;

  }
  .hero-sdp #heading {
    margin-top: 1rem;
}
  .hero-sdp .yt img{
    width: 354px;
    height: 190px;
  }
  .hero-sdp .container {
   flex-direction: column;
}
  .margin {
    height: 40vh;
  }
  .hero-sdp, .hero-sdp.aws{
    background-position: right;
    position: relative;
    height: 100vh;
  }
  .hero-sdp .row {
    width: 100%;
  }
}


@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { 
  .hero-sdp .yt img{
    width: 550px;
    height: 315px;
  }
}
