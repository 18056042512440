.newletter {
  background-color: #FF9900;
  padding: 50px 0;
  color: #fff;
}
.newletter .right,
.newletter .left {
  padding-right: 50px;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newletter i {
  padding: 12px;
  color: #FF9900;
  background-color: #fff;
}
footer {
  background-color: #1B2737;
  color: white;
}
.box.link a{
  color: white;
}
.box.link a:hover{
  color: #FF9900;
}
footer .sosmed{
  display: flex;
  gap: 1rem;
  justify-content: end;
}
footer .container {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}
@media screen and (max-width: 768px) {
  .newletter .right {
    margin-top: 1rem;
}
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
footer .logo span {
  color: #FF9900;
  font-size: 14px;
}
footer .logo p {
  color: white;
  margin: 0 0 15px 0;
}
footer .logo .icon {
  background-color: #FF9900;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 15px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #FF9900;
  margin-right: 5px;
}
footer .logo{
  width: 300px;
}
footer .logo img{
  width: 100%;
  object-fit: contain;
}

footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #FF9900;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #FF9900;
}
.legal {
  text-align: center;
  padding: 20px 0;
  color: white;
  background-color: #DA1C1B;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #FF9900;
}

.footer-top {
  width: 80%;
  margin: 0 auto;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .icon{
  background-color: rgba(255, 255, 255, 1);

}