.hero-sdp {
  background-image: url(../../../../../public/images/test1.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position-y: center;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #000;
}
.hero-sdp #heading h3 {
  color: #FF9900;
}
.hero-sdp #heading h1 {
  color: #1B2737  ;
}
.hero-sdp #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero-sdp .container{
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.hero-sdp .teks{
  width: 55%;
}
.hero-sdp .yt{
  display: flex;
  justify-content: start;
}
.hero-sdp .yt iframe{
  border-radius: 10px;

}

@media screen and (max-width: 1400px) {
  .hero-sdp .container{
    gap: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero-sdp {
    background-position: right;
    padding-top: 10%;
    height: 80vh;
  }
  .hero-sdp .row {
    width: 100%;
  }
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { 

}
