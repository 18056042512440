.hero-team {
  background-image: url("../../../../public/images/test1.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-y: center;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  width: 100%;
  height: 100vh;
  padding-top: 16%;
  padding-right: 50px;
  color: #000;
}
.hero-team .wrap-image{
  width: 380px;
}
.hero-team .wrap-image img{
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.hero-team #heading h3 {
  color: #FF9900;
}
.hero-team #heading h1 {
  color: #1B2737  ;
  margin: 10px 0;

}
.hero-team #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero-team .container{
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 3rem;

}
.hero-team .teks{
  width: 70%;
}
.hero-team .yt{
  display: flex;
  justify-content: start;
}
.hero-team .yt iframe{
  border-radius: 10px;

}
@media screen and (max-width: 768px) {
  iframe{
    margin-bottom: 1rem;
  }
  .hero-team .container{
    flex-direction: column;
    margin: 0 auto;

  }
  .margin {
    height: 40vh;
  }
  .hero-team {
    position: relative;
    background-position: end;
    margin: 0 auto;

    height: 110vh;
  }
  .hero-team .row {
    width: 100%;
  }
}


@media (min-width:1281px) { 
  .hero-team #heading h1 {
    color: #1B2737  ;
    margin: 20px 0;
  
  }
  .hero-team .wrap-image{
    width: 500px;
  }
}