.price .items {
  padding: 10px;
  /* text-align: center; */
}
.price .priceCard li{
  text-align: center !important;
}
.price h1 {
  font-size: 50px;
  text-align: center;
  margin: 20px 0;
}
.wrap-image {
  width: 150px;
  margin: 0 auto;
}
.wrap-image img{
  width: 100%;
  object-fit: contain;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  color: grey;
  margin: 40px 0;
}
.price ul{
  margin-top: 1rem;
  padding-left: 2rem;
}
.price li {
  list-style: disc;
  text-align:start ;
}
.price.price-solution  li {
  list-style: none;
}
/*--------------faq-------------*/
.faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #1eb2a6;
  color: #fff;
}

/*--------------faq-------------*/
@media screen and (max-width: 768px) {
  .price .items {
    padding: 30px;
    text-align: center;
  }
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { 
  .price .items {
    padding: 30px;
    /* text-align: center; */
  }
  .wrap-image {
    width: 240px;
  }
}