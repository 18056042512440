/*--------head------------*/
.head {
  padding: 20px 0;
  color: #000;
  position: relative;
  z-index: 9;

}
.wrap-logo {
width: 150px;
height: 50px;
}
.wrap-logo img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.66);
  margin: 0 30px;
  position: relative;
  z-index: 1;
}
header .nav {
  display: flex !important;
  
}
.submenu{
  display: none;
}
.active .submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px 30px;
  margin-left: -20px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);}
header li {
  margin-right: 40px;
  transition: all linear 0.3s;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #000;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #FF9900;
}
.start {
  background-color: #FF9900;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .aws{
    margin-top: -2rem;
  }
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #FF9900;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #FF9900;
  }
}
.dropdown-menu ul li{
  padding: .41rem 0;
}
.dropdown-menu ul a{
  padding: .41rem 0;
}
.dropdown-menu ul, .aws ul {
  background-color: #fff;
  transition: all linear 1.3s;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  padding: 20px 15px;
  flex-direction: column;
  display: flex;
  margin-top: 1rem;

}
.windows ul {
  background-color: #fff;
  transition: all linear 1.3s;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  padding: 20px 15px;
  flex-direction: column;
  display: flex;

}
.dropdown-menu,.dropdown-menu .aws ,.windows{
  position: absolute;
}
 .windows{
  margin-top: -2rem;
  height: 50px;
}
.dropdown-menu .aws{
  margin-top: -2rem;
}

.aws  ul,.windows ul{
  margin-left: 6.5rem;
 
}

.aws  a,.windows a{
  width: 150px;
 
}
